div#dev_footer {
    background-color: rgba(50, 53, 68, 0.509);
    align-content: center;
}

div#ContactInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

div#ContactInfo a{
    color: rgb(255,255,255);
}

div#ContactInfo a:hover h2{
    box-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
}