div#dev_header {
    background-color: rgba(50, 53, 68, 0.509);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

#dev_header_h1, #dev_header_nav{
    display: flex;
    align-items: center;
    justify-content: center;
}

#dev_header_nav ul{
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

#dev_header ul li {
    padding: 0 1vw;
}

#dev_header ul li a:hover h4 {
    cursor: pointer;
    text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
}

 



