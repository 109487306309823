@import url(https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Open+Sans&family=Source+Code+Pro&family=Yeseva+One&display=swap);
html {
    background-color: rgb(0, 0, 0);
    color: rgb(255,255,255);
    height: 100%;
    margin: 0;
    padding: 0;
}

body, div#root {
   padding: 0;
   margin: 0;
}

h1 {
    font-family: 'Yeseva One', cursive;
}

h2{
    font-family: 'Fredericka the Great', cursive;
}

h4 {
    font-family: 'Source Code Pro', monospace;
}

p {
    font-family: 'Open Sans', sans-serif;
}
div#dev_header {
    background-color: rgba(50, 53, 68, 0.509);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

#dev_header_h1, #dev_header_nav{
    display: flex;
    align-items: center;
    justify-content: center;
}

#dev_header_nav ul{
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

#dev_header ul li {
    padding: 0 1vw;
}

#dev_header ul li a:hover h4 {
    cursor: pointer;
    text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
}

 




div#dev_main {
  padding: 1vh 1vw;
}

#eyeCatcher {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

div#introStatement {
    padding: 0 2vw;
    text-shadow: 1px 0 10px rgb(71, 95, 184);
}

div#profile img{
    width: 70vw;
    padding: 0 2vw;
    max-width: 512px;
    height: auto;
}

span#emName {
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
}

  .About__header {
    padding-top: 1vh;
    text-align: center;
  }

.Bio, div section#aboutMe {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 90vw;
    padding: 0 2vw;
  }

div.Bio {
  padding: 2vh 0;
}

div section#aboutMe div.row_content {
  display: flex;
  flex-direction: column;
}
  
  .row_image {
    width: 70vw;
    max-width: 512px;
    padding: 0 2vw;
    border-radius: 50%;
    height: auto;
  }
 
 div#ProjectArea {
   padding: 1vh 1vw;
   display: flex;
   justify-content: center;
   align-content: center;
   height: auto;
   width: 100vw;
   max-height: 100vh;
 }

 #ProjectArea_Header {
  text-align: center;
}

 div#ProjectOverview {
  padding: 1vh 1vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  height: auto;
 }

 div#ProjectPhotos{
  display: flex;
  justify-content: center;
  align-content: center;
  width: auto;
 }



 div.Project__content {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-content: center;
  justify-items: center;
 }
 
 div.content__links_container {
    display: flex;
    justify-content: space-evenly;
    width: 70vw;
 }

 div.content__links_container a {
   color: white;
 }

 div.content__links_container a:hover {
  box-shadow: 0 0 2px 2px rgb(24, 72, 242);
}

 div.content__desc_container{
   display: flex;
   justify-content: center;
   text-align: justify;
   align-content: center;
   padding: 0 1vw;
   width: 80vw;
   font-size: minmax(2vmax, 1vmax);
 }

 div.content__stack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: justify;
  width: 80vw;
 }

 div#ProjectPhotos .Project__image{
   display: flex;
   align-content: space-between;
   justify-content: space-between;
   width:auto;
   max-width: 60vw;
   flex-direction: column;
 }

 div#ProjectPhotos img{
   width: auto;
   max-width: 60vw;
   height: auto;
   max-height: 40vh;
 }


  @media all and (min-width: 1000px) {
    .Bio {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    section#aboutMe {
      display: flex;
      align-content: center;
      justify-content: center;
    }

    div.row_content {
      display: flex;
      justify-content: center;
      align-content: center;
    }
  
    .reverse {
      flex-direction: row-reverse;
    }
  }


div#dev_footer {
    background-color: rgba(50, 53, 68, 0.509);
    align-content: center;
}

div#ContactInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

div#ContactInfo a{
    color: rgb(255,255,255);
}

div#ContactInfo a:hover h2{
    box-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
}
.lipco_app {
    display: grid;
    grid-template-rows: auto 1fr auto;
    padding: 0;
    margin: 0;
}

